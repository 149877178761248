code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*--------------------------------------------------------------
# Font
--------------------------------------------------------------*/
@font-face {
  font-family: "cleanvertisingblack";
  src: url(./assets/font/cleanveb-webfont.woff2) format("woff2"),
    url(./assets/font/cleanveb-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

.digi-font {
  font-family: cleanvertisingblack !important;
}

@font-face {
  font-family: "cleanvertising_light";
  src: url(./assets/font/cleanvel-webfont.woff2) format("woff2"),
    url(./assets/font/cleanvel-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

.digi-font-light {
  font-family: cleanvertising_light !important;
}


/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #4d4643;
}

a {
  color: #007bff;
}

a:hover {
  color: rgb(90, 186, 236);
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Muli", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  right: 15px;
  bottom: 15px;
  background: #f03c02;
  color: #fff;
  transition: display 0.5s ease-in-out;
  z-index: 99999;
}

.back-to-top i {
  font-size: 24px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.back-to-top:hover {
  color: #fff;
  background: rgb(90, 186, 236);
  transition: 0.3s ease-in-out;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background: #f8f6f5;
  padding: 10px 0;
  font-size: 14px;
}

#topbar .contact-info a {
  line-height: 1;
  color: #4d4643;
  transition: 0.3s;
}

#topbar .contact-info a:hover {
  color: rgb(90, 186, 236);
}

#topbar .contact-info i {
  color: #f03c02;
  padding: 4px;
}

#topbar .contact-info .icofont-phone {
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px solid #e9e9e9;
}

#topbar .social-links a {
  color: #836a61;
  padding: 4px 0 4px 20px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
}

#topbar .social-links a:hover {
  color: rgb(90, 186, 236);
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  padding: 10px 0;
  background: #fff;
}

#header.header-scrolled {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

#header .logo h1 a, #header .logo h1 a:hover {
  color: #2b2320;
  text-decoration: none;
  display: flex;
  align-items: center;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.nav-menu > ul > li + li {
  padding-left: 20px;
}

.nav-menu a, .nav-menu .menuCaption {
  display: block;
  position: relative;
  color: #65534c;
  padding: 14px 0 3px 0;
  transition: 0.3s;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
}

.nav-menu a.active, .nav-menu .menuCaption.active {
  color: #022d57;
  border-bottom: 2px solid rgb(90, 186, 236);
}

/* .nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a { */
.nav-menu a:hover, .nav-menu li:hover > a, .nav-menu navCaption:hover {
  color: rgb(90, 186, 236);
  text-decoration: none;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 20px;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
  margin: 0 40px;
}

.nav-menu .drop-down ul a {
  /* padding: 10px 20px; */
  font-size: 13px;
  font-weight: 500;
  text-transform: none;
  margin-bottom: 10px;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
  color: rgb(90, 186, 236);
}

.nav-menu .drop-down > a:after, .nav-menu .drop-down > .menuCaption:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

div.prFooterLinks {
  display: flex;
  flex-wrap: wrap;
}

div.top-row .col {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

div.footer-row {
  margin-top: 30px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: "\ea9d";
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 20px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #65534c;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a, .mobile-nav .menuCaption {
  display: block;
  position: relative;
  color: #2b2320;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
  text-transform: uppercase;
  font-size: 14px;
}

.mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
  color: #f03c02;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: "\eaa1";
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-active .mobile-nav-overly {
  display: block;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(14, 11, 10, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
  padding: 0;
}

#hero .carousel, #hero .carousel-inner, #hero .carousel-item, #hero .carousel-item::before {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 90%;
  width: 90%;
  /* bottom: 60px;
  top: 110px;
  left: 50px;
  right: 50px; */
}

#hero .carousel-content {
  background: rgba(28, 23, 21, 0.7);
  padding: 20px;
  color: #fff;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  border-top: 5px solid #f03c02;
}

#hero .carousel-content h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 700;
}

#hero .btn-get-started {
  font-family: "Muli", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #f03c02;
}

#hero .btn-get-started:hover {
  background: #f03c02;
  color: #fff;
  text-decoration: none;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-left,
#hero .carousel-inner .carousel-item-prev.carousel-item-right {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev, #hero .carousel-control-next {
  width: 10%;
}

#hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}

#hero .carousel-indicators li {
  cursor: pointer;
}

@media (min-width: 1024px) {
  #hero .carousel-content {
    width: 60%;
  }
  #hero .carousel-control-prev, #hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-width: 765px) {
  #hero {
    height: calc(100vh - 70px);
  }
  #hero .carousel-content h2 {
    margin-bottom: 15px;
    font-size: 22px;
  }
  #hero .carousel-content p {
    font-size: 15px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f6f3f2;
}

.section-title {
  padding-bottom: 30px;
  text-align: center;
}

.section-title h2 {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #65534c;
}

.section-title h2 strong {
  font-weight: 700;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #00365d;
  min-height: 40px;
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 300;
  color: #fff;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #ded5d2;
}

.breadcrumbs ol a {
  color: rgb(90, 186, 236);
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #c8bab5;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs h2 {
    font-size: 20px;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
  .breadcrumbs ol li + li {
    padding-left: 2px;
  }
  .breadcrumbs ol li + li::before {
    padding-right: 2px;
  }
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: #f3f1f0;
  padding: 60px 0;
  color: #574741;
}

.cta h3 {
  font-size: 28px;
  font-weight: 700;
}

.cta h3 span {
  color: #f03c02;
}

.cta p {
  padding: 0;
  margin: 0;
}

.cta .cta-btn {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 25px;
  border-radius: 2px;
  transition: 0.4s;
  margin: 10px;
  border-radius: 4px;
  border: 2px solid #f03c02;
  color: #f03c02;
  background: #fff;
}

.cta .cta-btn:hover {
  background: #f03c02;
  color: #fff;
}

@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}

@media (min-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  padding: 50px 20px 30px 20px;
  margin-top: 35px;
  margin-bottom: 25px;
  text-align: center;
  border: 1px solid #ebe6e4;
  height: 220px;
  position: relative;
  background: #faf9f8;
}

.services .icon {
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  transition: 0.2s;
  border-radius: 50%;
  border: 6px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 72px;
  height: 72px;
  background: #00365d;
}

.services .icon i {
  color: #fff;
  font-size: 24px;
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
  text-transform: uppercase;
}

.services .title {
  color: #343a40;
}

.services .icon-box:hover .icon {
  background: rgb(90, 186, 236)
  ;
  border: 2px solid #00365d;
}

.services .icon-box:hover .icon i {
  color: #00365d;
}

.services .icon-box:hover .title a {
  color: #00365d;
}

.services .description {
  line-height: 24px;
  font-size: 14px;
  color: #333;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 50px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 10px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #4d4643;
  transition: all 0.3s;
  border-radius: 50px;
}

.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #00365d;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}

.portfolio .portfolio-item:hover {
  transform: scale(1.1);
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 1;
  bottom: 5px;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,.1);
  border: 0.5px solid rgba(0,0,0,0.1);
  position: absolute;
  left: 30px;
  right: 30px;
  /* bottom: 0; */
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 15px 5px 15px;
  min-height: 75px;
  pointer-events: none;;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  font-weight: 600;
  color: #2b2320;
  text-shadow: 2px 6px 2px rgba(206,89,55,0), 2px 3px 3px rgba(51,51,51,0.14);
}

.portfolio .portfolio-item .portfolio-info p {
  color: #65534c;
  font-size: 14px;
  margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link, .portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #483b36;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover, .portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #00365d;
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}

.portfolio .portfolio-item .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-item .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-links a:hover {
  color: #fe825a;
}

/* .portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,.1);
  border: 0.5px solid rgba(0,0,0,0.1);
} */

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .clients-wrap > div {
  border-top: 1px solid #f1eeed;
  border-left: 1px solid #f1eeed;
}

.clients .client-logo {
  /* padding: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #f1eeed;
  border-bottom: 1px solid #f1eeed;
  overflow: hidden;
  background: #fff;
  height: 100px;
}

.clients .client-logo img {
  transition: all 0.3s ease-in-out;
  max-height: 100%;
  padding: 10px;
}

.clients .client-logo:hover img {
  -webkit-filter: none;
  filter: none;
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about-us .content {
  padding: 30px 0;
}

.about-us .content h3 {
  font-weight: 700;
  font-size: 34px;
}

/* .about-us .content p {
  margin-bottom: 0;
} */

.about-us .content .icon-box {
  margin-top: 25px;
}

.about-us .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.about-us .content .icon-box i {
  font-size: 48px;
  float: left;
  color: #00365d;
}

.about-us .content .icon-box p {
  font-size: 15px;
  color: #90847f;
  margin-left: 60px;
}

/* .about-us .content {
  text-align: left;
} */

/*Button*/

.podcast-button {
  background-color: #00365D;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  width: 150px;
}

/*End Button*/

.about-us .image {
  background: url(./assets/team/AdamNagus.jpg) center center no-repeat;
  background-size: cover;
  width: 380px;
  min-height: 300px;
  max-height: 500px;
}

.about-us .image-logo {
  background-size: contain;
  width: 200px;
  min-height: 100px;
  max-height: 200px;
}

.dm-logo {
  background: url(./assets/about/dm-logo.png) center center no-repeat;
}

.pearson-logo {
  background: url(./assets/about/pearson-logo.png) center center no-repeat;
}

.fti-logo {
  background: url(./assets/about/fti-logo.png) center center no-repeat;
}

.acn-logo {
  background: url(./assets/about/acn-logo.png) center center no-repeat;
}

.podcast .image-dp {
  background: url(./assets/logos/dp-logo.jpg) center center no-repeat;
  background-size: contain;
  width: 275px;
  min-height: 275px;
  max-height: 300px;
}

.podcast .image-ds {
  background: url(./assets/logos/ds-logo.jpg) center center no-repeat;
  background-size: contain;
  width: 275px;
  min-height: 275px;
  max-height: 300px;
}

.gtm-podcast .image-dp {
  background: url(./assets/logos/dp-logo.jpg) center center no-repeat;
  background-size: contain;
  width: 200px;
  min-height: 200px;
  max-height: 200px;
  margin: 10px;
}

.gtm-podcast .image-ds {
  background: url(./assets/logos/ds-logo.jpg) center center no-repeat;
  background-size: contain;
  width: 200px;
  min-height: 200px;
  max-height: 200px;
  margin: 10px;
}

.service-hero {
  width: 100%;
  min-height: 400px;
  max-height: 400px;
  margin-bottom: 20px;
}

.gtm-hero {
  background: url(./assets/services/GTM\ Strategy.jpg) center center no-repeat;
}

.diligence-hero {
  background: url(./assets/services/Digital\ Diligence.jpg) center center no-repeat;
}

.va-hero {
  background: url(./assets/services/Visual\ Analytics.jpg) center center no-repeat;
}

.analytics-strategy-hero {
  background: url(./assets/services/Analytics\ Strategy.jpg) center center no-repeat;
}

.partner-hero {
  background: url(./assets/services/Partner\ and\ Alliance\ Management.jpg) center center no-repeat;
}

.ai-hero {
  background: url(./assets/services/AI\ Product\ Strategy.jpg) center center no-repeat;
}

@media (max-width: 1024px) {
  .about-us .image {
    background: url(./assets/team/AdamNagus.jpg) top center no-repeat;
    background-size: cover;
    text-align: center;
    min-height: 275px;
  }

  .podcast .image-dp {
    background: url(./assets/logos/dp-logo.jpg) top center no-repeat;
    background-size: cover;
    text-align: center;
    min-height: 275px;
  }
  /* .about-us .image img {
    max-width: 80%;
  } */
}

/* @media (max-width: 667px) {
  .about-us .image img {
    max-width: 100%;
  }
} */

/*--------------------------------------------------------------
# Our Team
--------------------------------------------------------------*/
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
}

.team .member .social a {
  transition: color 0.3s;
  color: #2b2320;
  margin: 0 10px;
  padding-top: 8px;
  display: inline-block;
}

.team .member .social a:hover {
  color: #f03c02;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #2b2320;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #b4aca8;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #847872;
}

.team .member:hover .social {
  opacity: 1;
}

/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills .progress {
  height: 50px;
  display: block;
  background: none;
}

.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #2b2320;
  line-height: normal;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #ded5d2;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: .9s;
  background-color: #f03c02;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features {
  padding-top: 0;
}

.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  border: 0;
  padding: 25px 20px;
  transition: 0.3s;
  color: #2b2320;
}

.features .nav-link:hover {
  color: #f03c02;
}

.features .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
}

.features .nav-link p {
  font-size: 14px;
  margin-bottom: 0;
}

.features .nav-link.active {
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.12);
  color: #f03c02;
}

.features .tab-pane.active {
  -webkit-animation: slide-down 0.5s ease-out;
  animation: slide-down 0.5s ease-out;
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*--------------------------------------------------------------
# Partners
--------------------------------------------------------------*/
.partners .box {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #f3f1f0;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.partners h3 {
  font-weight: 400;
  padding-bottom: 10px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #00365d;
}

/* .partners h4 {
  font-size: 42px;
  color: #f03c02;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
} */

.partners h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.partners h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.partners ul {
  padding: 0;
  list-style: none;
  color: #2b2320;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.partners ul li {
  padding-bottom: 0;
}

.partners ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.partners .btn-wrap {
  padding: 15px;
  text-align: center;
}

.partners .btn-buy {
  color: #9c847b;
  border: 2px solid #e9e3e1;
  display: inline-block;
  padding: 10px 40px 12px 40px;
  border-radius: 4px;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Muli", sans-serif;
  font-weight: 600;
  transition: 0.3s;
}

.partners .btn-buy:hover {
  color: #f03c02;
  border-color: #f03c02;
}

.partners .featured {
  background: rgb(90, 186, 236);
  box-shadow: none;
}

.partners .featured h3, .partners .featured h4, .partners .featured h4 span, .partners .featured ul, .partners .featured ul .na {
  color: #fff;
}

.partners .featured .btn-wrap {
  padding: 15px;
  text-align: center;
}

.partners .featured .btn-buy {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.5);
}

.partners .featured .btn-buy:hover {
  color: #f03c02;
  background: #fff;
  border-color: #fff;
}

/*--------------------------------------------------------------
# Careers
--------------------------------------------------------------*/
.careers .career-item {
  text-align: center;
  box-sizing: content-box;
  padding: 30px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}

.careers .career-item .career-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}

.careers .career-item h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.careers .career-item h4 {
  font-size: 1.2rem;
  color: #999;
  margin: 0;
}

.careers .career-item .quote-icon-left, .careers .career-item .quote-icon-right {
  color: #fecfc0;
  font-size: 26px;
}

.careers .career-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.careers .career-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.careers .career-item p {
  font-style: italic;
  margin: 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-wrap {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.contact .info {
  background: #fff;
}

.book-time-button {
  margin: 10px 0px;
}

.book-time-button a {
  color: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #f03c02;
  float: left;
  width: 44px;
  height: 44px;
  background: #ffeee8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #2b2320;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #65534c;
}

.contact .info:hover i {
  background: #f03c02;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #f03c02;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: rgb(90, 186, 236);
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding: 40px;
}

.portfolio-details .portfolio-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 40px;
}

.portfolio-details .portfolio-details-carousel .owl-nav, .portfolio-details .portfolio-details-carousel .owl-dots {
  margin-top: 5px;
  text-align: center;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
  background-color: #f03c02 !important;
}

.portfolio-details .portfolio-info {
  text-align: left;
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-info p:not(:first-of-type) {
  font-size: 15px;
  padding: 15px 0 0 0;
}

@media (max-width: 992px) {
  .portfolio-details .portfolio-info {
    padding-top: 20px;
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}

.blog .entry {
  margin-bottom: 60px;
  padding: 20px;
  background: #f8f6f5;
  height: 505px;
}

/* .blog .entry-details {
} */

.blog .entry .entry-img {
  max-height: 400px;
  overflow: hidden;
  border: 4px solid #f8f6f5;
  height: calc(100% - 160px);
}

.blog .entry .entry-img img {
  width: 100%;
  max-height: 300px;
}

.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #2b2320;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: rgb(90, 186, 236);
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #9c847b;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li + li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 14px;
  padding-right: 4px;
}

.blog .entry .entry-meta a {
  color: #836a61;
  font-size: 14px;
  display: inline-block;
  line-height: 20px;
}

.blog .entry .entry-content p {
  line-height: 24px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #00365d;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
  background: rgb(90, 186, 236);
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #4d4643;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote .quote-left {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 36px;
  color: #e7e7e7;
}

.blog .entry .entry-content blockquote .quote-right {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 36px;
  color: #e7e7e7;
}

.blog .entry .entry-content blockquote::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #2b2320;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
}

.blog .blog-author h4 {
  margin-left: 140px;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
}

.blog .blog-author .social-links {
  margin: 0 0 5px 140px;
}

.blog .blog-author .social-links a {
  color: #9c847b;
}

.blog .blog-author p {
  margin-left: 140px;
  font-style: italic;
  color: #bfb9b6;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  width: 50px;
}

.blog .blog-comments .comment h5 {
  margin-left: 65px;
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #4d4643;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #f03c02;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #2b2320;
}

.blog .blog-comments .comment time {
  margin-left: 65px;
  display: block;
  font-size: 14px;
  color: #9c847b;
  margin-bottom: 5px;
}

.blog .blog-comments .comment p {
  margin-left: 65px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 0;
  padding: 20px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #fe825a;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 0;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #fe825a;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  padding: 8px 20px;
  border: 0;
  border-radius: 4px;
  background-color: #2b2320;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #f03c02;
}

.blog .blog-pagination {
  color: #65534c;
}

.blog .blog-pagination ul {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.blog .blog-pagination li {
  border: 1px solid white;
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li.active {
  background: white;
}

.blog .blog-pagination li a {
  color: #b4aca8;
  padding: 7px 16px;
  display: inline-block;
}

.blog .blog-pagination li.active, .blog .blog-pagination li:hover {
  background: #f03c02;
  border: 1px solid #f03c02;
  border-radius: 4px;
}

.blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .blog-pagination li.disabled {
  background: #fff;
  border: 1px solid white;
}

.blog .blog-pagination li.disabled i {
  color: #f6f5f5;
  padding: 10px 16px;
  display: inline-block;
}

.sidebar {
  padding: 30px;
  /* margin: 0 0 60px 20px; */
  background: #f8f6f5;
}

.sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #2b2320;
  position: relative;
}

.sidebar .sidebar-item {
  margin-bottom: 30px;
}

.sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
  border-radius: 4px;
}

.sidebar .search-form form input[type="text"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 40px);
}

.sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #f03c02;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.sidebar .search-form form button:hover {
  background: rgb(90, 186, 236);
}

.sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.sidebar .categories ul li + li {
  padding-top: 10px;
}

.sidebar .categories ul a {
  color: #745f56;
}

.sidebar .categories ul a:hover {
  color: rgb(90, 186, 236);
}

.sidebar .categories ul a span {
  padding-left: 5px;
  color: #9c847b;
  font-size: 14px;
}

.sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}

.sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.sidebar .recent-posts h4 a {
  color: black;
  transition: 0.3s;
}

.sidebar .recent-posts h4 a:hover {
  color: rgb(90, 186, 236);
}

.sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #9c847b;
}

.sidebar .tags {
  margin-bottom: -10px;
}

.sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.sidebar .tags ul li {
  display: inline-block;
}

.sidebar .tags ul a {
  color: #745f56;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #ded5d2;
  display: inline-block;
  transition: 0.3s;
  border-radius: 4px;
}

.sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid rgb(90, 186, 236);
  background: rgb(90, 186, 236);
}

.sidebar .tags ul a span {
  padding-left: 5px;
  color: #c8bab5;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: #b4aca8;
  font-size: 14px;
  background: #14100f;
  margin-top: 50px;
}

#footer .footer-top {
  padding: 20px 0 20px 0;
  background: #00365d;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 22px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Muli", sans-serif;
  color: #b4aca8;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

/* #footer .footer-top .footer-links {
  margin-bottom: 30px;
} */

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: rgb(90, 186, 236);
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #b4aca8;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: rgb(90, 186, 236);
}

#footer .footer-newsletter {
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px 0 0 4px;
  text-align: left;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -4px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #f03c02;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: rgb(90, 186, 236);
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: #b4aca8;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #00365d;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: rgb(90, 186, 236);
  color: #fff;
  text-decoration: none;
}


/**********************************************
  DM CSS
**********************************************/
.sticky-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #00365d;
}

#content-col {
  /* overflow-x: auto; */
  padding: 0 35px;
  background-color: white;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.breadcrumbs .pathName {
  text-transform: uppercase;
  margin: 0;
}

.clients {
  padding: 10px 0;
}

.clients h4 {
  font-size: 1.5rem;
  margin-top: 50px;
}

.clients h4:first-of-type {
  margin-top: 0;
}

.clients .imgContainer {
  display: flex;
  align-items: center;
  height: 150px;
  margin-bottom: 30px;
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.12);
  color: #00365d;
  overflow: hidden;
}

.clients .embed-responsive, .services .embed-responsive {
  background-color: lightgray;
}

.descContainer {
  overflow-y: auto;
  height: 100%;
}

.about-us .aboutVideos {
  text-align: left;
}

.about-us .aboutVideos h2 {
  font-size: 28px;
  font-weight: 300;
}

.about-us .aboutVideos.row {
  padding: 20px;
}

.about-us .videos .image {
  height: 300px;
  margin: 60px 0;
}

.careers table {
  text-align: left;
  margin-top: 30px;
}

.careers table td:last-of-type {
  padding-left: 50px;
}

#hero .hero-content {
  text-align: center;
  background: rgba(28, 23, 21, 0.7);
  padding: 20px;
  color: #fff;
  animation-duration: .5s;
  border-top: 5px solid white;
  position: absolute;
  top: 50%;
  width: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

#hero #particles-js {
  width: 99%;
  height: 99%
}

#hero #tsparticles {
  height: 100%;
}

.portfolio .portfolio-item {
  height: 196px;
}

.portfolio .demo-logo {
  height: 100%;
  border: 1px solid #ebe6e4;
}

.portfolio .demo-logo img {
  height: 100%;
}

.entry-details > .share {
  margin-bottom: 10px;
}

.share i {
  font-size: 2em;
}

.share i.icofont-linkedin {
  color: #0e76a8;
}

.share i.icofont-facebook {
  color: #3b5998;
}

.share i.icofont-twitter {
  color: #00acee;
}

.blog .misc-content img {
  padding-top: 10px;
  padding-bottom: 10px;
}

.blog a {
  color: #007bff;
}

.demoDetail .lightroom {
  margin-top: 20px;
  cursor: pointer;
}

.cookie-law {
  background: lightgray;
  border-radius: 17px;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  position: fixed;
  bottom: 5px;
  z-index: 9;
  width: 90%;
  left: 5%;
}

.cookie-law p {
  padding: 10px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  color: rgb(1, 48, 86);
  margin: 0;
}

.cookie-law .close-cookie-banner {
  border: none;
  background-color: transparent;
  color: #00365d;
  font-weight: bold;
  margin-left: 20px;
}

.entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.entry-footer i {
  /* color: #836a61; */
  display: inline;
  font-size: 2em;
}

.entry-footer .tagName {
  color: #ae9a92;
  transition: 0.3s;
}

/* .entry-footer .tagName:hover {
  color: #f03c02;
} */

.entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
  margin-left: 6px;
}

.entry-footer .cats li {
  display: inline-block;
}

.entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.entry-footer .tags li {
  display: inline-block;
}

.entry-footer .tags li + li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.entry-footer .share {
  font-size: 16px;
}

.entry-footer .share i {
  padding-left: 5px;
}

.partners, .partners a {
  color: #4d4643;
  transition: transform .4s;
}

.partners, .partners a:hover {
  transform: scale(1.05);
}

.partners img {
  height: 90px;
  margin-bottom: 15px;
}

.partners .entry-footer {
  margin-top: auto;
}

.partners .entry-footer i {
  font-size: 1.5em;
}

.blog.partner .entry {
  height: auto;
}

.pageButton {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.pageButton:focus {
  outline: none;
}

.pageButton.right {
  margin-left: auto;
}

.pageButton i {
  font-size: 40px;
  color: #00365d;
}

.dotContainer {
  padding: 20px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.dot {
  height: 10px;
  width: 10px;
  background: lightgray;
  border-radius: 50px;
  display: inline-block;
  box-shadow: 0px 0px 4px grey inset;
  margin-right: 2px;
}

.dot.active {
  background-color: #00365d;
  box-shadow: inset 0px 0px 4px #888888;
}

.training .icon {
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  transition: 0.2s;
  /* border-radius: 50%; */
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 72px;
  height: 72px;
  background-color: transparent;
}

.icon .diligence {
  padding: 5px;
}

.customLink span {
  pointer-events: none;
}

@media (min-width: 992px) {
  .partners .box {
    min-height: 375px;
  }

  .blog .entry:not(.entry-single) .entry-details h2 {
    height: 70px;
  }
}

@media (max-width: 992px) {
  .App {
    margin-top: 70px!important;
  }
  
  #hero .hero-content {
    width: calc(100% - 50px);
  }
  
  .blog .entry {
    height: auto;
  }

  .blog .entry .entry-img {
    height: auto;
  }

  .pageButton {
    display: none;
  }
}

@media (max-width: 576px) {
  .services .icon-box {
    height: auto;
  }
}

